<script>
import { computed, ref, watch } from 'vue'
import { pluralize } from '/~/utils/format/string'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'

export default {
  name: 'mobile-filter-select-field',
  components: {
    BaseCheckbox,
    BaseCollapse,
    BaseIcon,
    BaseLoader,
    BaseRadio,
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: undefined,
    },
    emptyOption: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
    title: {
      type: String,
      default: null,
    },
    options: {
      type: [Object, Array],
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isToggled = ref(false)
    const defaultValue = computed(() => (props.isMultiple ? [] : null))
    const filtersState = ref(props.value ?? defaultValue.value)
    const hasActiveFilters = computed(() => {
      return Boolean(
        filtersState.value &&
          ((props.isMultiple && filtersState.value.length > 0) ||
            (!props.isMultiple &&
              filtersState.value !== getOptionValue(props.emptyOption)))
      )
    })
    const filtersCount = computed(() =>
      props.isMultiple ? filtersState.value.length : 1
    )

    function isOptionSelected(option, key) {
      const optionValue = getOptionValue(option)

      return (
        (key === 0 && filtersState.value === null) ||
        (props.isMultiple
          ? filtersState.value?.includes(optionValue)
          : filtersState.value === optionValue)
      )
    }

    function getOptionValue(option) {
      return option?.id ?? option?.value ?? null
    }

    function getOptionLabel(option) {
      return option.label ?? option.text ?? null
    }

    watch(filtersState, () => {
      emit('input', filtersState.value)
    })

    watch(
      () => props.value,
      (value) => {
        filtersState.value = value ?? defaultValue.value
      },
      { deep: true }
    )

    return {
      isToggled,
      filtersState,
      hasActiveFilters,
      isOptionSelected,
      getOptionValue,
      getOptionLabel,
      pluralize,
      filtersCount,
    }
  },
}
</script>

<template>
  <base-collapse v-model="isToggled" :disabled="isLoading">
    <template #trigger>
      <span class="flex w-full items-center font-bold text-eonx-neutral-800">
        <span class="mr-auto">
          {{ title }}
        </span>
        <base-loader v-if="isLoading" size="xs" class="mr-2.5" />
        <span
          v-if="hasActiveFilters"
          class="mr-2.5 text-sm font-semibold text-primary"
        >
          {{ filtersCount }} active {{ pluralize(filtersCount, 'filter') }}
        </span>
        <base-icon
          :class="{
            'rotate-180 transform': isToggled,
          }"
          :size="12"
          svg="plain/chevron-bottom-v2"
        />
      </span>
    </template>
    <div
      v-show="!isLoading && options && options.length > 0"
      class="flex flex-wrap items-center gap-2.5 px-2.5 pt-4"
    >
      <component
        :is="isMultiple ? 'base-checkbox' : 'base-radio'"
        v-for="(option, key) in options"
        :key="`_${key}`"
        v-model="filtersState"
        :value="getOptionValue(option)"
        :hide-input="true"
      >
        <span
          class="block rounded-full py-[5px] px-[15px] text-sm font-semibold"
          :class="{
            'bg-gray-50': !isOptionSelected(option, key),
            'bg-primary text-white': isOptionSelected(option, key),
          }"
        >
          {{ getOptionLabel(option) }}
        </span>
      </component>
    </div>
  </base-collapse>
</template>
